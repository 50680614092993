{
  "name": "speedscope",
  "version": "1.22.2",
  "description": "",
  "repository": "jlfwong/speedscope",
  "main": "index.js",
  "bin": {
    "speedscope": "./bin/cli.mjs"
  },
  "scripts": {
    "deploy": "./scripts/deploy.sh",
    "prepack": "./scripts/prepack.sh --outdir \"$(pwd)/dist/release\" --protocol file",
    "prettier": "prettier --write 'src/**/*.ts' 'src/**/*.tsx'",
    "lint": "eslint 'src/**/*.ts' 'src/**/*.tsx'",
    "jest": "./scripts/test-setup.sh && jest --runInBand",
    "coverage": "npm run jest -- --coverage",
    "typecheck": "tsc --noEmit",
    "test": "./scripts/ci.sh",
    "serve": "tsx scripts/dev-server.ts"
  },
  "files": [
    "bin/cli.mjs",
    "dist/release/**",
    "!*.map"
  ],
  "browserslist": [
    "last 2 Chrome versions",
    "last 2 Firefox versions"
  ],
  "author": "",
  "license": "MIT",
  "devDependencies": {
    "@types/jest": "22.2.3",
    "@types/jszip": "3.1.4",
    "@types/node": "14.0.1",
    "@types/pako": "1.0.0",
    "@typescript-eslint/eslint-plugin": "6.16.0",
    "@typescript-eslint/parser": "6.16.0",
    "acorn": "7.2.0",
    "aphrodite": "2.1.0",
    "esbuild": "0.24.2",
    "eslint": "8.0.0",
    "eslint-plugin-prettier": "5.1.2",
    "eslint-plugin-react-hooks": "4.6.0",
    "jest": "24.3.0",
    "jsverify": "0.8.3",
    "jszip": "3.1.5",
    "pako": "1.0.6",
    "preact": "10.4.1",
    "prettier": "3.1.1",
    "protobufjs": "6.8.8",
    "source-map": "0.6.1",
    "ts-jest": "24.3.0",
    "tsx": "4.19.2",
    "typescript": "5.3.3",
    "typescript-json-schema": "0.42.0",
    "uglify-es": "3.2.2",
    "uint8array-json-parser": "0.0.2"
  },
  "jest": {
    "transform": {
      "^.+\\.tsx?$": "ts-jest"
    },
    "setupFilesAfterEnv": [
      "./src/jest-setup.js"
    ],
    "testRegex": "\\.test\\.tsx?$",
    "collectCoverageFrom": [
      "**/*.{ts,tsx}",
      "!**/*.d.{ts,tsx}"
    ],
    "moduleFileExtensions": [
      "ts",
      "tsx",
      "js",
      "jsx",
      "json"
    ]
  },
  "dependencies": {
    "open": "10.1.0"
  }
}
